export default {
  address: "台南市北區北安路一段314號",
  googleSrc:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.1031249045386!2d120.20435081496768!3d23.019985484954375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346e76ff989f0c6b%3A0x3874c4dd8238fcb0!2zNzA05Y-w5Y2X5biC5YyX5Y2A5YyX5a6J6Lev5LiA5q61MzE06Jmf!5e0!3m2!1szh-TW!2stw!4v1619888303755!5m2!1szh-TW!2stw",
  googleLink: "https://goo.gl/maps/2AeoPvXVkJ1Frswp7",
  phone: "06-283-5866",
  fbLink:
    "https://www.facebook.com/chunfubeautifulyear",
  fbMessage: "https://m.me/chunfubeautifulyear/",
  caseName: "春福年年",
  indigatorLength: 10,
  houseInfos: [
    ["投資新建", "春上建設有限公司"],
    ["建築設計", "大磊聯合建築師事務所"],
    ["企劃銷售", "上揚國際建築團隊"],
    ["接待中心", "台南市北區北安路一段314號"],
    ["建照號碼", "(110)南工造字第01522號"]
  ],

  gtmCode: ["WF2G3HX","PB7F29N"], // 可放置多個
  recaptcha_site_key_v2: "6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz",
  recaptcha_site_key: "6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s", // recaptcha v3
  recaptcha_user_token: "6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa",
  order: {
    title: "預約賞屋",
    subTitle: "若想了解建案資訊，歡迎填寫表單或來電洽詢，將由專人為您服務，謝謝！"
  }
};
